<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md8>
      <v-card class="elevation-12">
        <v-toolbar dark color="primary">
          <v-toolbar-title>Forgot Password</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <p style="background-color:yellow;padding:5px;font-weight:bolder">NOTICE: If you have not registered/subscribed to the new LiveCad system, you must do so first before attempting to reset your password.  You login account from the old system does not work with the new LiveCad system</p>
          <v-form>
            <v-text-field
              v-model="emailAddress"
              prepend-icon="mdi-person"
              name="email"
              label="Email Address"
              type="text"
              :error-messages="resetPasswordError.emailAddress"
            ></v-text-field>
          </v-form>
          <v-alert
            type="success"
            :value="hasResetPassword"
          >Your request has been sent. If we have that email address on file, you'll receive an email in a couple minutes.</v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" :to="{name: 'home'}">Cancel</v-btn>
          <v-btn color="primary" @click="onSubmit">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      emailAddress: ""
    };
  },
  computed: mapState({
    resetPasswordError: state => state.resetPasswordError,
    hasResetPassword: state => state.hasResetPassword
  }),
  methods: {
    ...mapActions(["resetPassword"]),
    onSubmit() {
      this.resetPassword({
        emailAddress: this.emailAddress
      });
    },
    onResult() {}
  }
};
</script>